import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import './LandingPage.css';
import {
  CategoryScale,
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function LandingPage() {
  const [topServices, setTopServices] = useState([]);
  const navigate = useNavigate();

  const fetchReportsForService = async (service) => {
    try {
      const response = await axios.get(`https://disapi.armann-systems.com/reports`, {
        params: { dienstTitel: service.Titel }
      });

      const reportCounts = Array(6).fill(0);
      const labels = Array.from({ length: 6 }, (_, idx) => {
        const hour = new Date().getHours() - idx;
        return hour < 0 ? hour + 24 : hour;
      }).reverse();

      response.data.forEach(report => {
        const reportHour = new Date(report.Timestamp).getHours();
        const hourIndex = labels.indexOf(reportHour);
        if (hourIndex !== -1) {
          reportCounts[hourIndex]++;
        }
      });

      return {
        ...service,
        chartData: {
          labels: labels.map(label => `${label}:00`),
          datasets: [{
            label: 'Anzahl der Störungen',
            data: reportCounts,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        }
      };
    } catch (error) {
      console.error(`Fehler beim Abrufen der Berichte für ${service.Titel}:`, error);
      return service;
    }
  };

  useEffect(() => {
    const loadTopServices = async () => {
      try {
        const response = await axios.get('https://disapi.armann-systems.com/top-services');
        const topFiveServices = response.data.slice(0, 5);
        const servicesWithReports = await Promise.all(topFiveServices.map(fetchReportsForService));
        setTopServices(servicesWithReports);
      } catch (error) {
        console.error('Fehler beim Abrufen der Top-Dienste:', error);
      }
    };

    loadTopServices(); // Initialer Aufruf
    const intervalId = setInterval(loadTopServices, 30000); // 30000 ms = 30 Sekunden

    return () => clearInterval(intervalId); // Bereinigen des Intervalls
  }, []);

  const handleReportIssueClick = () => {
    navigate('/services');
  };

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function(value) {
            if (Number.isInteger(value)) {
              return value;
            }
          }
        }
      }
    }
  };

  return (
    <div className="landing-page">
      <Helmet>
        <title>//A - Störungen</title>
        <meta name="description" content="Erfahren Sie den Status Ihrer IT-Services und melden Sie bequem Störungen bei Armann Systems." />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Armann Systems GmbH" />
      </Helmet>
      <header className="header">
        <h1>Willkommen bei Armann Systems Störungsmeldungen</h1>
        <p>Erfahren Sie den Status Ihrer IT-Services und melden Sie bequem Störungen.</p>
        <button onClick={handleReportIssueClick} className="cta-button">Störung melden</button>
      </header>
      <section className="features">
        <div className="feature">
          <h2>Einfache Bedienung</h2>
          <p>Melden Sie Störungen schnell und unkompliziert über unsere Plattform.</p>
        </div>
        <div className="feature">
          <h2>Immer informiert</h2>
          <p>Bleiben Sie auf dem Laufenden über den Zustand Ihrer IT-Services.</p>
        </div>
        <div className="feature">
          <h2>Zusammenarbeit</h2>
          <p>Helfen Sie anderen Nutzern durch das Teilen von Informationen zu Störungen.</p>
        </div>
      </section>

      <section className="top-services">
        <h2>Top 5 Dienste mit den meisten Störungen</h2>
        {topServices.map(service => (
          <Link to={`/services/${service.Titel}`} key={service.DienstID} style={styles.link}>
            <div className="service-item">
              <img
                src={`https://img.ashub.de/services/${service.DienstName}.png`}
                alt={`${service.Titel} Logo`}
                className="service-logo"
              />
              <div>
                <span>{service.Titel}<br></br></span>
                <span className="service-report-count">
                  Letzte Stunde: {service.AnzahlStörungen} Störungen
                </span>
              </div>
              <div className="chart-container">
                <Line data={service.chartData} options={chartOptions} />
              </div>
            </div>
          </Link>
        ))}
      </section>
    </div>
  );
}

const styles = {
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
};

export default LandingPage;
